<template>
  <div>
    <div class="beijing">
      <p class="shou">推荐中心</p>
      <!-- <p class="shou">推荐中心</p> -->
      <p class="zun">尊敬的候选人：</p>
      <div class="gen">
        根据我们的内部讨论结果，我们觉得您的经历非常优秀，但和我们目前的需求不匹配，不过我们觉得一些同行对您会非常感兴趣，不知道您愿不愿意我们将您的履历
        转介绍 和 推荐
        给萝卜猎手平台上的其他同行，让更匹配您的企业关注到您，以替您寻求更好的工作机会。请随时留意电话或短信，届时将有新的企业会联系您。
      </div>
      <div class="gs clearfix">{{ gs }}</div>
      <!-- <div class="gs clearfix">订单订单订单订单订单订单订单订单订</div> -->

      <span class="zf">祝您职顺安祺</span>
    </div>
    <div @click="$router.push('/explain')" class="u_btn_add">
      <!-- <p class="bb">萝卜猎手职业互助平台简介</p> -->
      <p class="bb">萝卜猎手职业互助平台简介 >></p>
    </div>
    <p class="tips1" style="margin-bottom: 0.2rem; margin-top: 0.54rem">
      萝卜小贴士：
    </p>

    <p class="tips">
      1、请认真查看并完善您的信息（推荐企业上传资料或许有误），有助于让更多企业看到您的闪光点！
      如您有其他疑问，请通过萝卜猎手（公众号）联系官方。,<br />
      2、根据国家相关法规，如您不同意该推荐/转介绍，请短信息回复“拒绝推荐”，或点击下文按钮“拒绝推荐”，我们将终止本次推荐。,<br />
      3、如您24小时内未回复，将默认同意推荐，但您可以随时点击本链接取消或拒绝推荐。
    </p>

    <!-- <p class="bb">萝卜猎手HR联盟平台简介 >></p> -->
    <!-- <p class="explain">
      根据国家相关法规，如您不同意该推荐/转介绍，请短信息回复“拒绝推荐”，或点击下文按钮“拒绝推荐”，我们将终止本次推荐。
    </p> -->

    <div class="btn">
      <!-- <a href="./html/msg.html" class="u_btn_add"> -->
      <p id="refuse" @click="refuse">拒绝推荐</p>

      <p id="triggerBtn" @click="ty">同意并完善信息</p>

      <!-- <p id="triggerBtn" @click="ty">同意并完善信息</p> -->
      <!-- </a> -->
    </div>
    <van-popup v-model="show">
      <div class="box">
        <van-icon
          class="cross"
          name="cross"
          size="0.45rem"
          @click="show = false"
        />
        <img src="../assets/nanguo-2.png" alt="" />
        <div class="box2">
          <p>10万HR正在等着你，</p>
          <p>真的狠心拒绝吗？</p>
        </div>
        <div class="res" @click="ty">{{ res }}</div>
        <div class="eer" @click="twice">{{ eer }}</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      res: "同意推荐",
      eer: "狠心拒绝",
      num: 1,
      gs: "",
    };
  },
  created() {
    // localStorage.setItem("id", this.$route.query);
    // console.log(this.$route.query);
    // this.$route.query.forEach((item) => {
    //   console.log(item);
    // });
    let id = "";
    // Object.keys(this.$route.query).forEach((res) => {
    //   console.log(res);
    //   if (res.indexOf("/") != -1) {
    //     res = res.substr(0, res.indexOf("/"));
    //     id = res;
    //   } else {
    //     res = res.substr(0, res.length);
    //     id = res;
    //   }
    // });
    let res = this.$route.query.id;
    if (res.indexOf("/") != -1) {
      res = res.substr(0, res.indexOf("/"));
      id = res;
    } else {
      res = res.substr(0, res.length);
      id = res;
    }
    id = atob(id);
    if (id == "") {
      localStorage.removeItem("id");
    } else {
      localStorage.setItem("id", id);
    }
    console.log(localStorage.getItem("id"));
    // localStorage.setItem("id", 355);
    localStorage.removeItem("education");
    localStorage.removeItem("agelimit");
    localStorage.removeItem("intention");
    localStorage.removeItem("station");
    localStorage.removeItem("advantage");
    localStorage.removeItem("face");
    localStorage.removeItem("area");
    localStorage.removeItem("pay");
    localStorage.removeItem("moblie");
    localStorage.removeItem("mail");
    localStorage.removeItem("birthday");
    localStorage.removeItem("sex");
    localStorage.removeItem("mobile");
    localStorage.removeItem("username");
    localStorage.removeItem("list");
    localStorage.removeItem("census");
    localStorage.removeItem("statusseeking");
    localStorage.removeItem("honor");
    localStorage.removeItem("major");
    localStorage.removeItem("wz");
    this.$http
      .post("/firm/v1/resume/getResumeInfo", {
        reqType: "resume",
        id: id,
      })
      .then((res) => {
        res = JSON.parse(res.data);
        this.gs = res.data.company;
        console.log(this.gs);
      })
      .catch((eer) => {
        console.log(eer);
      });
  },
  methods: {
    //同意分享简历
    ty() {
      let id = localStorage.getItem("id");
      this.$http
        .post("/firm/v1/userinfo/setStatus", {
          reqType: "resume",
          id,
          type: 1,
        })
        .then((res) => {
          res = JSON.parse(res.data);
          console.log(res);
          if (res.code == 2005) {
            this.$toast.fail(res.msg);
            return;
          }
          if (res.code == 1001) {
            this.$toast.fail("链接参数有误");
            return;
          }
          // if (res.code == 11028) {
          //   this.$toast.fail("已失效");
          //   return;
          // }
          this.$router.push("/msg");
        })
        .catch((eer) => {
          console.log(eer);
        });
    },
    //取消
    refuse() {
      this.eer = "狠心拒绝";
      this.num = 1;
      this.show = true;
    },
    //二次拒绝
    twice() {
      this.eer = "残忍拒绝";
      if (this.num == 2) {
        let id = localStorage.getItem("id");
        this.$http
          .post("/firm/v1/userinfo/setStatus", {
            reqType: "userinfo",
            id,
            type: 2,
          })
          .then((res) => {
            res = JSON.parse(res.data);
            // if (res.code == 11028) {
            //   this.$toast.fail("已失效");
            //   return;
            // }
            if (res.code == 1001) {
              this.$toast.fail("链接参数有误");
              return;
            }
            this.$router.push({
              name: "ending",
              params: {
                num: 2,
              },
            });
          })
          .catch((eer) => {
            console.log(eer);
          });
      }
      this.num = 2;
    },
  },
};
</script>

<style scoped lang='less'>
.zf {
  margin-top: 0.8rem;
  margin-right: 0.98rem;
  display: block;
  text-align: right;
  font-size: 0.3rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.clearfix::before,
.clearfix::after {
  content: "";
  display: table;
}
.clearfix::after {
  clear: both;
}
.clearfix {
  *zoom: 1;
}
.beijing {
  width: 100%;
  height: 8rem;
  /* background: #F6601A; */
  /* border-radius: 0rem 0rem 2.2rem 2.2rem; */
  background: url("../assets/yyy.png") no-repeat;
  background-size: 100%;
}
.tips1 {
  padding: 0 0.81rem;
  font-size: 0.24rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}
.tips {
  padding: 0 0.81rem;

  line-height: 0.46rem;
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.gs {
  margin-right: 0.98rem;
  text-align: right;
  width: 6.1rem;
  margin-top: 0.2rem;
  float: right;
  font-size: 0.3rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.beijing .shou {
  padding-top: 0.8rem;
  text-align: center;
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 0.1rem;
  line-height: 0.48rem;
}

.beijing .zun {
  margin-top: 0.3rem;
  margin-left: 0.82rem;
  font-size: 0.36rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 0.52rem;
}

.beijing .gen {
  margin: 0rem 0.81rem;
  text-indent: 2em;
  font-size: 0.3rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 0.52rem;
}

.beijing .gong {
  float: right;
  margin-right: 0.81rem;
  font-size: 0.3rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 0.4rem;
  margin-top: 0.15rem;
  line-height: 0.52rem;
}

.bb {
  font-size: 0.24rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #f6601a;
  line-height: 0.33rem;
  letter-spacing: 0.01rem;
  text-align: center;
  margin-top: 0.2rem;
}

.explain {
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  line-height: 0.4rem;
  margin: 0.62rem 0.81rem 0;
}

.btn {
  margin-top: 0.5rem;
  display: flex;
  margin-left: 0.47rem;
  margin-bottom: 1.04rem;
}

.btn #triggerBtn {
  margin-left: 0.4rem;
  font-size: 0.36rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  line-height: 0.96rem;
  height: 0.96rem;
  background: #f6621c;
  border-radius: 0.16rem;
  padding: 0 0.5rem;
  margin-right: 0.38rem;
}

.btn #refuse {
  height: 0.96rem;
  font-size: 0.36rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #f6621c;
  line-height: 0.96rem;
  background: rgba(248, 148, 100, 0.41);
  border-radius: 0.16rem;
  padding: 0 0.53rem;
  white-space: nowrap;
}

.u_btn_add {
  text-decoration: none;
}
.box {
  position: relative;
  width: 5.04rem;
  height: 5.21rem;
  background-color: #fff;
  .cross {
    position: absolute;
    right: 0.2rem;
    top: 0.2rem;
  }
  img {
    position: absolute;
    left: 50%;
    top: 0.98rem;
    transform: translateX(-50%);
    width: 1.2rem;
    height: 1.2rem;
  }
  .box2 {
    position: absolute;
    left: 0.73rem;
    top: 2.43rem;
    width: 3.42rem;
    height: 1.36rem;
    font-size: 0.35rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 0.68rem;
    p {
      text-align: center;
    }
  }
  .res {
    position: absolute;
    left: 0;
    bottom: 0;
    text-align: center;
    font-size: 0.32rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    line-height: 0.88rem;
    width: 2.52rem;
    height: 0.88rem;
    background-color: #f65a16;
    color: #fff;
  }
  .eer {
    position: absolute;
    right: 0;
    bottom: 0;
    text-align: center;
    font-size: 0.32rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    line-height: 0.88rem;
    width: 2.52rem;
    height: 0.88rem;
    background-color: #fdd5c4;
    color: #f65a16;
  }
}
/deep/ .van-popup--center {
  border-radius: 0.25rem;
}
</style>